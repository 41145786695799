import React from "react";
import get from "lodash/get";
import { updateAttributeOnDocument } from "util/pouchActions";

import ThumbsUpIcon from "icons/ThumbsUpIcon";
import ThumbsDownIcon from "icons/ThumbsDownIcon";
import ClockIcon from "icons/ClockIcon";
import ResetIcon from "icons/ResetIcon";
import VideoIcon from "icons/VideoIcon";
import { useMeet } from "util/useMeet";
import {
  AttemptNumber,
  AttemptResult,
  LiftName,
  Lifter,
  LiftingOrder,
  Meet,
} from "types";
import { Column } from "components/table/Table";
import { useKnownLiftingOrder } from "util/useKnownLiftingOrder";

const startClock = ({ lifter, meet }: { lifter: Lifter; meet: Meet }) => {
  if (lifter.platformId) {
    updateAttributeOnDocument(
      meet._id,
      lifter.platformId,
      "clockState",
      "started"
    );
  }
};

const resetClock = ({ lifter, meet }: { lifter: Lifter; meet: Meet }) => {
  if (lifter.platformId) {
    updateAttributeOnDocument(
      meet._id,
      lifter.platformId,
      "clockState",
      "initial"
    );
  }
};

const markAttempt = ({
  result,
  lifter,
  lift,
  attemptNumber,
  meet,
  selectNextAttempt,
  knownLiftingOrder,
}: {
  result: AttemptResult;
  lifter: Lifter;
  lift: LiftName | undefined | null;
  attemptNumber: AttemptNumber | undefined | null;
  meet: Meet;
  selectNextAttempt: any;
  knownLiftingOrder: LiftingOrder;
}) => {
  if (lift && attemptNumber) {
    const attempt = get(lifter, ["lifts", lift, attemptNumber]);

    updateAttributeOnDocument(meet._id, attempt._id, "result", result);
    selectNextAttempt(knownLiftingOrder);
  }
};

const ActionButtons = ({
  lifter,
  column,
}: {
  lifter: Lifter;
  column: Column;
}) => {
  const meet = useMeet();
  const knownLiftingOrder = useKnownLiftingOrder();
  const goodAttemptButton = React.useRef<HTMLButtonElement>(null);
  const badAttemptButton = React.useRef<HTMLButtonElement>(null);
  const startClockButton = React.useRef<HTMLButtonElement>(null);
  const resetClockButton = React.useRef<HTMLButtonElement>(null);

  const handleKeyPress = React.useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 71) {
      // g key pressed
      goodAttemptButton.current?.click();
    } else if (event.keyCode === 66) {
      // b key pressed
      badAttemptButton.current?.click();
    } else if (event.keyCode === 83) {
      // s key pressed
      startClockButton.current?.click();
    } else if (event.keyCode === 82) {
      // r key pressed
      resetClockButton.current?.click();
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  if (!lifter.platformId) {
    return null;
  }

  const platform = meet.platforms[lifter.platformId];

  if (!platform) {
    return null;
  }

  const lift = column.lift;
  const attemptNumber = column.attemptNumber;

  if (!lift || !attemptNumber) {
    return null;
  }

  const attempt = lifter.lifts?.[lift]?.[attemptNumber];

  if (!attempt) {
    return null;
  }

  return (
    <div>
      <div className="button-row">
        <button
          ref={goodAttemptButton}
          className="attempt-button"
          onClick={() =>
            markAttempt({
              knownLiftingOrder,
              result: "good",
              lifter,
              lift: column.lift,
              attemptNumber: column.attemptNumber,
              meet,
              selectNextAttempt: column.selectNextAttempt,
            })
          }
        >
          <ThumbsUpIcon />
        </button>
        <button
          ref={badAttemptButton}
          className="attempt-button"
          onClick={() =>
            markAttempt({
              knownLiftingOrder,
              result: "bad",
              lifter,
              lift: column.lift,
              attemptNumber: column.attemptNumber,
              meet,
              selectNextAttempt: column.selectNextAttempt,
            })
          }
        >
          <ThumbsDownIcon />
        </button>
      </div>
      {!meet.virtualMeet && (
        <div className="button-row">
          <button
            ref={startClockButton}
            className="attempt-button"
            onClick={() => startClock({ lifter, meet })}
            disabled={platform.clockState === "started"}
          >
            <ClockIcon />
          </button>
          <button
            ref={resetClockButton}
            className="attempt-button"
            onClick={() => resetClock({ lifter, meet })}
            disabled={platform.clockState === "initial"}
          >
            <ResetIcon />
          </button>
        </div>
      )}
      {meet.virtualMeet && attempt && attempt.videoUrl && (
        <div className="button-row">
          <a
            href={attempt.videoUrl}
            target="_blank"
            className="attempt-button"
            rel="noreferrer"
          >
            <VideoIcon />
          </a>
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
