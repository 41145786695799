import React from "react";
import get from "lodash/get";
import every from "lodash/every";
import classNames from "classnames";
import { Meet } from "types";
import { RecordsForAttempt } from "components/cells/RecordsForAttempt";

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else if (lifter.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  } else {
    const attempt = get(lifter, ["lifts", column.lift, column.attemptNumber]);
    if (!attempt || !get(lifter, ["division", "lifts", column.lift])) {
      return null;
    }

    let isCurrentAttempt = false;
    every(meet.platforms, (platform) => {
      isCurrentAttempt =
        get(meet, ["platforms", platform._id, "currentAttemptId"]) ===
        get(attempt, "_id");

      if (isCurrentAttempt) {
        return false; //break loop
      }

      return true; // continue loop
    });

    return (
      <div
        style={style}
        className={classNames(
          "table-cell-inner",
          "table-cell-inner-attempt",
          attempt.result || "",
          {
            "current-attempt": isCurrentAttempt,
          }
        )}
      >
        <div>{get(attempt, column.key)}</div>
        <div className="attempt-flags">
          <RecordsForAttempt attempt={attempt} />
          {!!attempt.juryOverride && <div>J</div>}
        </div>
      </div>
    );
  }
};

export default AttemptCell;
