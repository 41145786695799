import React from "react";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import VideoIcon from "icons/VideoIcon";
import { isCompetingInLift } from "util/lifterHelper";
import { Meet } from "types";
import { RecordsForAttempt } from "components/cells/RecordsForAttempt";

const AttemptCell = ({
  data,
  column,
  meet,
  style,
}: {
  data: any;
  column: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const { platformId } = useParams<{
    platformId: string;
  }>();

  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  }

  if (data.row === "title") {
    return <div style={style} className="table-cell-inner" />;
  }

  const attempt = get(lifter, ["lifts", column.lift, column.attemptNumber]);
  if (!isCompetingInLift(lifter, get(attempt, "liftName"), meet)) {
    return null;
  }
  const isCurrentAttempt =
    get(meet, ["platforms", platformId, "currentAttemptId"]) ===
    get(attempt, "_id");

  return (
    <div
      style={style}
      className={classNames(
        "table-cell-inner",
        "table-cell-inner-attempt",
        attempt.result || "",
        {
          "current-attempt": isCurrentAttempt,
        }
      )}
    >
      <div>{get(attempt, "weight")}</div>
      <div className="attempt-flags">
        <RecordsForAttempt attempt={attempt} />
        {!!attempt.juryOverride && <div>J</div>}
        {!!attempt.endOfRound && <div>*</div>}
      </div>
      {meet.virtualMeet && attempt && attempt.videoUrl && attempt.result && (
        <a
          className="video-url"
          target="_blank"
          rel="noreferrer"
          href={attempt.videoUrl}
        >
          <VideoIcon />
        </a>
      )}
    </div>
  );
};

export default AttemptCell;
