import uniq from "lodash/uniq";
import React from "react";
import { Attempt } from "types";
import { useRecordAttempts } from "util/useRecordAttempts";

export const RecordsForAttempt = ({ attempt }: { attempt: Attempt }) => {
  const allRecords = useRecordAttempts();

  const recordsForAttempt = allRecords?.[attempt._id];
  if (!recordsForAttempt?.length) {
    return null;
  }

  const formattedRecords = recordsForAttempt?.map((r) => {
    return `R${r.lift === "T" ? "T" : ""}`;
  });

  const uniqRecords = uniq(formattedRecords);

  return (
    <>
      {uniqRecords.map((r) => (
        <div key={r}>{r}</div>
      ))}
    </>
  );
};
